.footerDiv {
  display: flex;
  justify-content: space-between;
  background-color: #3d2d10;
  padding: 64px 50px;
  padding-bottom: 37px;
  color: white;
  border-top: 1px solid white;
}

.leftDiv h1,
.rightDiv h1 {
  font-family: "Playfair Display", serif;
}

.leftDiv p,
.rightDiv p {
  color: white;
}

.iconsDiv {
  display: flex;
  background-color: #3d2d10;
  padding: 0 50px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #3d2d10;
}

.iconStyle {
  font-size: 30px;
  color: white;
  margin-right: 25px;
}

.copyrightDiv {
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: #3d2d10;
  font-weight: 700;
  padding: 13px 50px;
}

@media all and (max-width: 950px) {
  .footerDiv {
    display: block;
  }
  .rightDiv {
    margin-top: 4vh;
  }
  .copyrightDiv {
    padding: 5px 10px;
  }
}
