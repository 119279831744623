.collMainDiv {
  padding: 0 10%;
  height: 100vh;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.collDiv {
  width: 100%;
  display: block;
  justify-content: space-between;
}

.mainHeading {
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 40px;
}

.btnsDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btnWrapper {
  margin-top: 10%;
  width: 25%;
  display: flex;
  justify-content: center;
}

.btn {
  width: fit-content;
  margin: 10px 0;
  padding: 15px 25px;
  color: white;
  font-weight: 700;
  background-color: #3d2d10;
  border-radius: 50px;
  border: 2px solid transparent;
  /* box-shadow: inset 0px 0px 0px 2px white; */
}

.btn:hover {
  cursor: pointer;
  background-color: transparent;
  color: #3d2d10;
  font-weight: bold;
  border: 2px solid #3d2d10;
  transition: all 0.4s ease-out;
}

.btnText {
  font-size: 20px;
  font-family: "Playfair Display", serif;
}

@media all and (max-width: 950px) {
  .collMainDiv {
    padding: 0 10%;
    height: 100vh;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .collDiv {
    width: 100%;
    display: block;
    justify-content: space-between;
  }

  .mainHeading {
    font-family: "Playfair Display", serif;
    text-align: center;
    font-size: 40px;
  }

  .btnsDiv {
    display: block;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .btnWrapper {
    margin: 0 auto;
    margin-top: 10%;
    display: flex;
    width: 90%;
  }

  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
