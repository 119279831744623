.mainIntroDiv {
  padding: 10px 10%;
  padding-top: 15vh;
  height: 90vh;
}

.introDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.introDivImg {
  width: 40%;
  height: 80vh;
  border-radius: 0px 0px 0px 100px;
  object-fit: cover;
}

.introDivText {
  width: 40%;
  padding: 20px;
  align-self: center;
  text-align: start;
}

.typerText {
  display: flex;
  font-size: 20px;
  justify-content: start;
}

.viewBtn {
  width: fit-content;
  margin: 10px 0;
  padding: 15px 25px;
  color: white;
  font-weight: bold;
  background-color: #3d2d10;
  border-radius: 10px;
  border: 2px solid transparent;
  /* box-shadow: inset 0px 0px 0px 2px white; */
}

.viewBtn:hover {
  cursor: pointer;
  background-color: transparent;
  color: #3d2d10;
  font-weight: bold;
  border: 2px solid #3d2d10;
  transition: all 0.4s ease-out;
}

@media all and (max-width: 950px) {
  .mainIntroDiv {
    padding: 25px 25px;
    display: contents;
  }

  .mobSpacer {
    height: 10vh;
  }

  .introDiv {
    display: flex;
    flex-direction: column;
  }

  .introDivImg {
    width: 80%;
    align-self: center;
  }

  .introDivText {
    width: 80%;
    margin-bottom: 100px;
    align-self: center;
  }
  .typerText {
    font-size: 15px;
    padding-left: 2px;
  }
}

/* 
.Intro {
  height: 100vh;
  margin: 0 10%;
  padding-top: 10vh;
  display: grid;
  place-items: center;
}

.mainLogoDiv {
}

.mainLogoDiv img {
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

.logoTextDiv {
  display: grid;
  place-items: end;
}

.mainLogoText {
  color: black;
  font-size: 96px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}

.mainLogoSubText {
  color: black;
  font-size: 20px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  top: -10px;
} */
