.Cart {
  height: 100%;
  padding: 0 10%;
  padding-top: 70px;
  background-color: #3d2d10;
  color: white;
}

.NoCart {
  height: 100vh;
  padding: 0 10%;
  background-color: #3d2d10;
  color: white;
  display: grid;
  place-items: center;
}

.heading {
  font-family: "Playfair Display", serif;
  text-align: center;
  margin: 5vh 0;
  font-size: 40px;
}

.amountHeading {
  text-align: center;
  padding-bottom: 5vh;
}

.mainCartDiv {
  display: flex;
  flex-direction: column;
}

.cartDiv {
  display: flex;
  width: 100%;
  height: 50vh;
  margin: 5vh 0;
  background-color: white;
  border-radius: 50px;
}

.previewImgDiv {
  height: 100%;
  aspect-ratio: 1/1;
  position: relative;
}

.iconDiv {
  display: grid;
  place-content: center;
  width: 50px;
  margin: 10px 10px;
  aspect-ratio: 1/1;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}

.iconDiv:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
}

.icon {
  font-size: 30px;
  color: white;
}

.cartDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px 0px 0px 50px;
}

.rightDiv {
  margin: 5vh;
}

.rightDiv p {
  color: #3d2d10;
  margin-bottom: 10px;
}

.name {
  font-size: 30px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}

.variant {
  font-size: 20px;
}

.size {
  font-size: 20px;
}

.removeBtn {
  font-weight: 700;
  font-size: 20px;
  padding: 12px 30px;
  border: 2px solid #3d2d10;
  color: #3d2d10;
  border-radius: 50px;
  background-color: white;
  margin: 20px 0;
}

.removeBtn:hover {
  cursor: pointer;
  background-color: #3d2d10;
  color: white;
}

@media all and (max-width: 950px) {
  .previewImgDiv {
    width: 50%;
  }
}

@media all and (max-width: 600px) {
  .cartDiv {
    width: 100%;
    height: 70vh;
    flex-direction: column;
    border-radius: 30px;
  }

  .previewImgDiv {
    height: 50%;
    width: 100%;
    aspect-ratio: 1/1;
  }

  .cartDiv img {
    border-radius: 30px 30px 0px 0px;
  }

  .rightDiv {
    margin: 5px 10px;
    display: flex;
    flex-direction: column;
    height: 50%;
  }

  .removeBtn {
    width: 100%;
    margin-top: auto;
  }
}
