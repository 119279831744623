.aboutMainDiv {
  padding: 0 10%;
  height: 100vh;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutDiv {
  width: 100%;
  display: block;
  justify-content: space-between;
}

.mainHeading {
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 40px;
}

.abtDiv1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.abtDiv1Text {
  margin: 0 10% 0 0;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  font-size: 20px;
  color: #3d2d10;
}

.abtDiv1Img {
  width: 28%;
  aspect-ratio: 3/2.5;
  border-radius: 10px 10px 10px 120px;
  object-fit: cover;
}

.abtDiv2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.abtDiv2Text {
  margin: 0 0 0 10%;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  font-size: 20px;
  color: #3d2d10;
  text-align: right;
}

.abtDiv2Img {
  width: 28%;
  aspect-ratio: 3/2.5;
  border-radius: 20px 120px 20px 10px;
  object-fit: cover;
}

@media all and (max-width: 950px) {
  .aboutMainDiv {
    padding: 0 10%;
    display: contents;
    height: 100vh !important;
  }

  .aboutDiv {
    display: flex;
    flex-direction: column;
  }

  .abtDiv1 {
    display: grid;
    place-items: center;
  }

  .abtDiv1Text {
    margin: 10%;
    font-weight: 700;
    font-family: "Playfair Display", serif;
    font-size: 20px;
    color: #3d2d10;
  }

  .abtDiv1Img {
    margin-bottom: 10%;
    width: 80%;
    aspect-ratio: 3/2.5;
    border-radius: 10px 10px 10px 120px;
    object-fit: cover;
  }

  .abtDiv2 {
    display: grid;
    place-items: center;
  }

  .abtDiv2Text {
    margin: 10%;
    font-weight: 700;
    font-family: "Playfair Display", serif;
    font-size: 20px;
    color: #3d2d10;
  }

  .abtDiv2Img {
    margin-top: 10%;
    width: 80%;
    aspect-ratio: 3/2.5;
    border-radius: 20px 120px 20px 10px;
    object-fit: cover;
  }
}
