@import url("https://fonts.googleapis.com/css2?family=Faustina:wght@300;400;500;600;700&family=Playfair+Display:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  text-decoration: none;
  font-family: "Faustina", serif;
}

.App {
  height: 100%;
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

select {
  background-color: white;
  border: thin solid #3d2d10;
  border-radius: 50px;
  display: inline-block;
  line-height: 1.5rem;
  padding: 5px 20px;

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, #3d2d10 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #3d2d10 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: #3d2d10;
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
