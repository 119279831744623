.Home {
  height: 90vh;
  padding: 0 10%;
  width: 100vw;
  padding-top: 30vh;
}

.arrowToTop {
  display: none;
}

.icon {
  font-size: 30px;
  color: #3d2d10;
}

@media all and (max-width: 950px) {
  .arrowToTop {
    display: grid;
    place-content: center;
    position: fixed;
    z-index: 20;
    bottom: 20px;
    right: 20px;
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    box-shadow: -2px 2px 5px #3d2d10;
  }
}
