.submenu {
  display: block;
  width: 13rem;
  position: absolute;
  list-style: none;
  text-align: start;
  top: 10vh;
}

.submenu li {
  background-color: #3d2d10;
  cursor: pointer;
}

.submenuItem {
  display: block;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 7px 12px;
  border-bottom: 1px solid #e5cb99;
}

.submenuItem:hover {
  color: #3d2d10;
  background-color: white;
  font-weight: 700;
}
