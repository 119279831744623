.overlayDiv {
  position: fixed;
  z-index: 500;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.overlayDiv .overlayImg {
  margin: auto;
  width: 90%;
  height: 90%;
  overflow: auto;
  transition: all 0.3s ease 0s;
}

.overlayImg:hover {
  transform: scale(1.5);
}

.iconDiv {
  display: grid;
  place-content: center;
  width: 50px;
  margin: 10px 20px;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  border-radius: 50px;
}

.iconDiv:hover {
  cursor: pointer;
}

.icon {
  font-size: 30px;
  color: black;
}

.dialogDiv {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 50px;
  padding: 20px;
}

.dialogDiv h1 {
  color: #3d2d10;
  text-align: center;
  margin: 20px 0;
}

.dialogDiv p {
  color: #3d2d10;
  text-align: center;
  margin-bottom: 40px;
}

.dialogDiv input {
  border-radius: 30px;
  padding: 10px 5px;
  margin-bottom: 10px;
  border: 2px solid #3d2d10;
}

.dialogDiv button {
  width: fit-content;
  align-self: center;
  margin-top: 40px;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 15px;
  background-color: white;
  border: 2px solid #3d2d10;
  border-radius: 100px;
  color: #3d2d10;
}

.dialogDiv button:hover {
  cursor: pointer;
  background-color: #3d2d10;
  color: white;
}

@media all and (max-width: 950px) {
  .dialogDiv {
    margin: 0 5vh;
    border-radius: 30px;
  }
}
