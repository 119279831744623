.reviewsMainDiv {
  padding: 0 10%;
  height: 110vh;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reviewsDiv {
  width: 100%;
  display: block;
  justify-content: space-between;
}

.mainHeading {
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 40px;
}

.reviewsCarousel {
  margin-top: 5vh;
  width: 100%;
  height: 90%;
}

.reviewCardDiv {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.reviewCardActive {
  width: 80%;
  height: fit-content;
  background-color: #3d2d10;
  border-radius: 20px;
  display: grid;
  place-items: start;
}

.reviewCardInactive {
  width: 60%;
  height: fit-content;
  background-color: white;
  border-radius: 20px;
  display: grid;
  place-items: start;
}

.activeIcon {
  color: white;
  transform: rotate(180deg);
  width: 150px !important;
  height: 150px !important;
}

.inActiveIcon {
  color: #3d2d10;
  transform: rotate(180deg);
  width: 50px !important;
  height: 50px !important;
  margin-top: 10px;
  margin-left: 10px;
}

.activeText {
  font-weight: 400;
  font-size: 18px;
  color: white;
  text-align: start;
  margin-bottom: 20px;
  margin-left: 20px;
}

.inActiveText {
  font-weight: 400;
  font-size: 16px;
  color: #3d2d10;
  text-align: start;
  margin: 0 20px;
  margin-bottom: 10px;
}

.activeSubCard {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: start;
  border-radius: 0 0 20px 20px;
  background-color: #664c1a;
  color: white;
}

.activeSubCard h4 {
  margin: 0 20px;
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
}

.activeSubCard p {
  margin: 0 20px;
  font-weight: 700;
  font-size: 15px;
}

.galleryBtn {
  width: 85%;
  border: 2px solid white;
  border-radius: 50px;
  padding: 12px 0;
  margin: 15px auto;
  color: white;
}

.galleryBtn p {
  font-size: 15px;
  margin: auto;
}

.galleryBtn:hover {
  cursor: pointer;
  border: 2px solid white;
  transition: all 0.2s ease-in-out;
  background-color: white;
  color: #3d2d10;
}

.inActiveSubCard {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: start;
  border-radius: 0 0 20px 20px;
  background-color: #d9d9d9;
  color: #3d2d10;
}

.inActiveSubCard h4 {
  margin: 0 20px;
  margin-top: 10px;
  font-weight: 700;
  font-size: 15px;
}

.inActiveSubCard p {
  margin: 0 20px;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 10px;
}

.ourWorkBtnDiv {
  width: fit-content;
  margin: auto;
  margin-top: 50px;
}

.ourWorkBtn {
  font-size: 20px;
  color: #3d2d10;
  font-family: "Playfair Display", serif;
  padding: 10px 20px;
  border: 2px solid #3d2d10;
  width: fit-content;
  border-radius: 50px;
  font-weight: 700;
}

.ourWorkBtn:hover {
  border: 2px solid white;
  background-color: white;
  color: #3d2d10;
  cursor: pointer;
}
