nav {
  position: fixed;
  z-index: 100;
  padding: 0 10%;
  width: 80%;
  height: 70px;
  background-color: white;
  box-shadow: 0 4px 25px rgb(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

nav img {
  width: 50px;
  height: 50px;
}

.navList {
  list-style: none;
  display: flex;
  text-align: center;
  justify-content: flex-end;
  margin-right: 2rem;
  width: 85vw;
}

.navItem {
  display: flex;
  align-items: center;
  height: 10vh;
}

.navItem p {
  color: #4e4d49;
  font-weight: 700;
  font-size: 20px;
  margin: 0 25px;
}

.navItem p::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}

.navItem p:hover {
  cursor: pointer;
  color: black;
  transition: all 0.2s ease-out;
}

.navItem p:hover::after {
  width: 100%;
}

.activeLink {
  cursor: pointer;
  color: black;
  transition: all 0.2s ease-out;
}

.activeLink::after {
  display: block;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}

.extraButtons {
  display: flex;
}

.iconBtnDiv {
  border: 2px solid #4e4d49;
  border-radius: 100%;
  color: #4e4d49;
  text-align: center;
  padding: 10px 12px;
  margin: 0 10px;
}

.iconBtnDivActive {
  border: 2px solid #3d2d10;
  border-radius: 100%;
  color: white;
  text-align: center;
  padding: 10px 12px;
  margin: 0 10px;
  background-color: #3d2d10;
}

.iconBtnDiv:hover {
  border: 2px solid #3d2d10;
  cursor: pointer;
  color: white;
  background-color: #3d2d10;
}

.musicBtn {
  border: 2px solid #4e4d49;
  border-radius: 100%;
  color: #4e4d49;
  text-align: center;
  padding: 12px;
  margin: auto 10px;
}

.musicBtn:hover {
  border: 2px solid #3d2d10;
  cursor: pointer;
  color: white;
  background-color: #3d2d10;
}

.menuBtn {
  display: none;
}

.menuBtnIcon {
  font-size: 50px !important;
  color: #3d2d10;
}

.mobileMainNavDiv {
  display: none;
}

@media all and (max-width: 950px) {
  nav {
    position: absolute;
    display: flex;
    justify-content: space-between;
  }
  nav img {
    width: 50px;
    height: 50px;
  }

  .navList {
    display: none;
  }

  .extraButtons {
    display: none;
  }

  .menuBtn {
    display: grid;
    place-self: center;
  }

  .mobileMainNavDiv {
    width: 100vw;
    display: flex;
    justify-content: end;
  }

  .mobileBtnSection {
    position: absolute;
    z-index: 50;
    width: 100vw;
    height: 100vh;
    background-color: #3d2d10;
    border-left: 2px solid white;
  }
  .mobileSecDiv {
    margin-top: 10vh;
    display: grid;
    place-content: center;
  }
}
