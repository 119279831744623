.MainDiv {
  padding: 0 10%;
  padding-top: 10vh;
  background-color: #3d2d10;
  color: white;
}

.NoItemMainDiv {
  height: 100vh;
  padding: 0 10%;
  background-color: #3d2d10;
  color: white;
  display: grid;
  place-items: center;
}

.heading {
  font-family: "Playfair Display", serif;
  text-align: center;
  margin: 5vh 0;
}

.variantMainDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5vh;
}

.variantDiv {
  width: 45%;
  display: flex;
  margin: 5vh 0;
  height: 60vh;
  background-color: white;
  color: #3d2d10;
  border-radius: 50px;
}

.previewImgDiv {
  width: 60%;
  position: relative;
}

.iconDiv {
  display: grid;
  place-content: center;
  width: 50px;
  margin: 10px 10px;
  aspect-ratio: 1/1;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}

.iconDiv:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
}

.icon {
  font-size: 30px;
  color: white;
}

.variantDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px 0px 0 50px;
}

.variantRightDiv {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 44px 30px;
}

.variantDiv h1 {
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}

.viewMoreBtn {
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  padding: 12px 0;
  border: 2px solid #3d2d10;
  color: #3d2d10;
  border-radius: 50px;
}

.viewMoreBtn:hover {
  cursor: pointer;
  background-color: #3d2d10;
  color: white;
  transition: 0.2s all ease-in;
}

@media all and (max-width: 950px) {
  .variantDiv {
    width: 100%;
    display: flex;
    margin: 5vh 0;
    height: 60vh;
    background-color: white;
    color: #3d2d10;
    border-radius: 50px;
  }

  .variantRightDiv {
    padding: 30px 15px;
  }
}

@media all and (max-width: 600px) {
  .variantDiv {
    width: 100%;
    display: block;
    height: 100vh;
    border-radius: 30px;
  }

  .previewImgDiv {
    width: 100%;
    height: 50%;
  }

  .variantDiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px 30px 0 0px;
  }

  .variantRightDiv {
    width: 90%;
    height: 44%;
    padding: 5% 5%;
  }

  .iconDiv {
    width: 40px;
  }

  .icon {
    font-size: 25px;
  }

  select {
    margin-bottom: 10%;
  }
}
