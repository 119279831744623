.aboutMainDiv {
  padding: 0 10%;
  height: 100vh;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutDiv {
  width: 100%;
  display: grid;
  place-items: center;
}

/* .leftDiv {
  width: 25%;
  height: fit-content;
  display: block;
  align-items: start;
} */

.logoTextDiv {
  display: grid;
  place-items: end;
  margin-bottom: 15vh;
}

.mainLogoText {
  color: black;
  font-size: 100px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  display: flex;
}

.mainLogoSubText {
  color: black;
  font-size: 20px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  top: -10px;
  margin-right: 20px;
}

.whatNewText {
  font-size: 30px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  margin-bottom: 5vh;
}

.bottomDiv {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.bottomDiv img {
  width: 33%;
  aspect-ratio: 1/1;
}

.leftImg {
  border-radius: 10px 120px 10px 10px;
  object-fit: cover;
}

.rightImg {
  border-radius: 120px 10px 10px 10px;
  object-fit: cover;
}

/* .leftDivText {
  font-weight: 400;
  font-size: 20px;
} */

/* .divImg {
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 20px;
  object-fit: cover;
} */

/* .topDiv {
  width: 25%;
  height: fit-content;
  display: block;
  align-items: center;
}

.topDivHeading {
  text-align: center;
  font-family: "Playfair Display", serif;
}

.topDivText {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
} */

/* .rightDiv {
  width: 25%;
  height: fit-content;
  display: block;
  align-items: end;
  align-self: self-end;
}

.rightDivHeading {
  text-align: end;
  font-family: "Playfair Display", serif;
}

.rightDivText {
  font-weight: 400;
  font-size: 20px;
  text-align: end;
} */

@media all and (max-width: 950px) {
  .aboutMainDiv {
    padding: 0 10%;
    display: contents;
  }

  .aboutDiv {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
  }

  /* .leftDiv {
    width: 80%;
    padding: 10vh 10%;
  } */

  .logoTextDiv {
    display: grid;
    place-items: end;
    margin-bottom: 10vh;
  }

  .whatNewText {
    margin-bottom: 3vh;
  }

  .mainLogoText {
    font-size: 70px;
  }

  .bottomDiv {
    display: flex;
    flex-direction: column;
  }

  .bottomDiv img {
    width: 80%;
    margin: 5vh auto;
  }

  /* .leftDivText {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
  }

  .divImg {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 20px;
    object-fit: cover;
  }

  .topDiv {
    width: 80%;
    padding: 10vh 10%;
  } */

  /* .topDivHeading {
    text-align: center;
    font-family: "Playfair Display", serif;
  }

  .topDivText {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
  }

  .rightDiv {
    width: 80%;
    padding: 10vh 10%;
  }

  .rightDivHeading {
    text-align: center;
    font-family: "Playfair Display", serif;
  } */

  /* .rightDivText {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
  } */
}
