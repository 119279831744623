.MainDiv {
  padding: 0 10%;
  padding-top: 10vh;
  background-color: #3d2d10;
  color: white;
}

.NoImageMainDiv {
  height: 100vh;
  padding: 0 10%;
  background-color: #3d2d10;
  color: white;
  display: grid;
  place-items: center;
}

.galleryMainDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5vh;
}
.heading {
  font-family: "Playfair Display", serif;
  text-align: center;
  margin: 5vh 0;
}

.previewImgDiv {
  width: 45%;
  display: flex;
  margin: 5vh 0;
  height: 60vh;
  background-color: white;
  color: #3d2d10;
  border-radius: 50px;
  position: relative;
}

.previewImgDiv img {
  width: 100%;
  border-radius: 50px;
}

.iconDiv {
  display: grid;
  place-content: center;
  width: 50px;
  margin: 10px 10px;
  aspect-ratio: 1/1;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}

.iconDiv:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
}

.icon {
  font-size: 30px;
  color: white;
}

.contactBtn {
  position: fixed;
  z-index: 10;
  bottom: 20px;
  right: 20px;
  background-color: white;
  color: #3d2d10;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 700;
  box-shadow: -2px 2px 5px #3d2d10;
  font-size: 20px;
  width: fit-content;
  display: flex;
  text-align: center;
}

.contactBtn:hover {
  cursor: pointer;
}

@media all and (max-width: 950px) {
  .previewImgDiv {
    width: 100%;
  }

  .previewImgDiv img {
    width: 100%;
    object-fit: cover;
  }

  .contactBtn {
    right: 0;
    left: 20px;
  }
}

@media all and (max-width: 600px) {
  .previewImgDiv {
    border-radius: 30px;
  }

  .previewImgDiv img {
    border-radius: 30px;
  }

  .iconDiv {
    width: 40px;
  }

  .icon {
    font-size: 25px;
  }
}
