.contactMainDiv {
  padding: 0 10%;
  height: 100vh;
  padding-top: 10vh;
  margin-bottom: 4vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactDiv {
  width: 100%;
  display: block;
  justify-content: space-between;
}

.contactSubDiv {
  display: flex;
  justify-content: space-between;
}

.mainHeading {
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 40px;
}

.left {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 30%;
}

.linkItem {
  display: flex;
  width: 100%;
  margin: 20px 20px;
}

.linkItem a {
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  color: white;
}
.linkItem a p {
  align-self: center;
  color: #3d2d10;
  font-weight: 700;
  font-size: 16px;
}

.iconStyle {
  color: #3d2d10;
  font-size: 30px;
  margin-right: 10px;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 0 0;
}

.dropMsgText {
  font-weight: 700;
  color: #3d2d10;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.right input {
  margin: 10px;
  padding: 5px 10px;
  border: 2px solid transparent;
  border-radius: 20px;
  width: 50%;
}

.right input:hover {
  outline: 2px solid #3d2d10 !important;
  box-shadow: 0 0px 15px #3d2d10;
  transition: all 0.2s ease-out;
}

.right input:focus {
  outline: 2px solid #3d2d10 !important;
  box-shadow: 0 0px 15px #3d2d10;
  transition: all 0.2s ease-out;
}

.right textarea {
  resize: none;
  margin: 10px;
  padding: 5px 10px;
  border: 2px solid transparent;
  border-radius: 20px;
  width: 50%;
}

.right textarea:focus {
  outline: 2px solid #3d2d10 !important;
  box-shadow: 0 0px 15px #3d2d10;
  transition: all 0.2s ease-out;
}

.right textarea:hover {
  outline: 2px solid #3d2d10 !important;
  box-shadow: 0 0px 15px #3d2d10;
  transition: all 0.2s ease-out;
}

.right button {
  margin-top: 10px;
  font-weight: 700;
  font-size: 20px;
  color: white;
  background-color: transparent;
  border: 2px solid white;
  padding: 10px 30px;
  border-radius: 50px;
  cursor: pointer;
}

.right button:hover {
  background-color: #3d2d10;
  border: 2px solid #3d2d10;
  transition: all 0.2s ease-out;
}

.mapDiv iframe {
  margin-top: 20px;
  border-radius: 20px;
  width: 100%;
  aspect-ratio: 3/2.5;
}

@media all and (max-width: 950px) {
  .contactMainDiv {
    justify-content: contents;
    height: 130%;
  }
  .contactSubDiv {
    flex-direction: column;
  }

  .left {
    width: 80%;
  }

  .linkItem a p {
    font-size: 90%;
  }
  .iconStyle {
    font-size: 170%;
  }
  .right {
    width: 80%;
    align-self: center;
  }

  .right input {
    width: 100%;
  }
  .right textarea {
    width: 100%;
  }
}
