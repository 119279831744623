.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  padding: 8px 16px !important;
  border-radius: 100%;
  color: #3d2d10 !important;
}

.swiper-pagination-bullet-active {
  background-color: #3d2d10 !important;
}

@media all and (max-width: 950px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}
