ul {
  list-style: none;
  display: grid;
}

.navItem {
  height: 10vh;
  display: grid;
  place-content: center;
}

.navItem p {
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.icon {
  font-size: 30px;
}

.iconBtnDiv {
  display: flex;
  text-align: center;
  color: white;
  font-size: 30px;
}

.iconBtnDivActive {
  color: #3d2d10;
  font-size: 30px;
  background-color: white;
  display: flex;
  text-align: center;
  padding: 10px 15px;
  border-radius: 50px;
}

.iconBtnDivActive p {
  color: #3d2d10;
}
